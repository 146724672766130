import React from "react"
import { Table, TableContainer } from "./styles"

export default function LimitsTable() {
  return (
    <TableContainer>
      <div>
        <Table className="border">
          <tr>
            <th style={{ textAlign: "left" }} colSpan={6}>
              Limits
            </th>
          </tr>

          <tr>
            <th>Concurrent Logins</th>
            <td>1</td>
            <td>1</td>
            <td>1</td>
          </tr>

          <tr>
            <th>Max Devices</th>
            <td>Unlimited</td>
            <td>Unlimited</td>
            <td>Unlimited</td>
          </tr>
        </Table>
      </div>
    </TableContainer>
  )
}
