import React from "react"
import { Cross } from "."
import { Table, TableContainer } from "./styles"

export default function LicenseTable() {
  return (
    <TableContainer>
      <div>
        <Table className="border">
          <tr>
            <th style={{ textAlign: "left" }} colSpan={6}>
              License
            </th>
          </tr>

          <tr>
            <th>&nbsp;</th>
            <th>Community Edition</th>
            <th>Basic</th>
            <th>Pro</th>
          </tr>

          <tr>
            <th>License Type</th>
            <td>
              <Cross />
            </td>
            <td>Monthly</td>
            <td>Monthly</td>
          </tr>

          <tr>
            <th>Support Ticket Response Time</th>
            <td>
              <Cross />
            </td>
            <td>72h</td>
            <td>48h</td>
          </tr>

          <tr>
            <th>Yearly Subscription USD</th>
            <td>
              <Cross />
            </td>
            <td>500</td>
            <td>Coming Soon</td>
          </tr>
        </Table>
      </div>
    </TableContainer>
  )
}
