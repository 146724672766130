import React from "react"
import PricingHead from "./PricingHead"
import LicenseTable from "./LicenseTable"
import LimitsTable from "./LimitsTable"
import MyPersonaFeatureTable from "./MyPersonaFeatureTable"
import { IoCloseSharp, IoCheckmarkSharp } from "react-icons/io5"

const Cross = () => (
  <IoCloseSharp color="red" fontSize="1.5rem" style={{ margin: "auto" }} />
)

const Tick = () => (
  <IoCheckmarkSharp
    color="green"
    fontSize="1.5rem"
    style={{ margin: "auto" }}
  />
)

export {
  Tick,
  Cross,
  PricingHead,
  LicenseTable,
  LimitsTable,
  MyPersonaFeatureTable,
}
