import React from "react"
import { ShadowBox, Table, TableContainer } from "./styles"

export default function PricingHead({ yearly }) {
  return (
    <TableContainer>
      <Table>
        <tr>
          <th>
            <h1>Features by Plan</h1>
          </th>
          <td>
            <ShadowBox className="h100">
              <h5 className="head">Community</h5>
              <h5 className="price">Free</h5>
            </ShadowBox>
          </td>
          <td>
            <ShadowBox className="h100">
              <h5 className="head">Essential</h5>
              {/* <h5 className="price">${yearly ? "500/year" : "50/month"}</h5> */}
              <h5 className="price">-</h5>
            </ShadowBox>
          </td>
          <td>
            <ShadowBox className="h100d">
              <h5 className="head">Professional</h5>
              <h5 className="price">Coming Soon</h5>
            </ShadowBox>
          </td>
        </tr>
      </Table>
    </TableContainer>
  )
}
