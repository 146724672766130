import React, { useState } from "react"
import styled from "styled-components"
import Layout from "../components/Layout"
import {
  MyPersonaFeatureTable,
  LicenseTable,
  LimitsTable,
  PricingHead,
} from "../components/pricingComponents"
import Seo from "../components/Seo"

const ToggleContainer = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: "row";

  & > h6 {
    margin: 10px;
  }

  @media screen and (max-width: 769px) {
    flex-direction: column;
  }
`

const Toggle = styled.div`
  padding: 10px 40px;
  border-radius: 20px;
  background-color: ${({ active }) => (active ? "#1c365e" : "#F9C712")};
  color: ${({ active }) => (active ? "white" : "#1c365e")};
  position: relative;
  z-index: ${({ active }) => (active ? "1" : "0")};
  font-weight: bold;
  cursor: pointer;

  &.right {
    margin-left: -30px;
  }
`

const PricingPage = () => {
  const [yearly, setYearly] = useState(true)

  const toggle = () => {
    setYearly(!yearly)
  }

  return (
    <Layout>
      <Seo title="Pricing" />
      <div className="bg-bgColor pt-5">
        <h2 className="text-center">Pricing Plan</h2>
        <div className="p-4 lg:px-1 xl:w-4/5 xl:m-auto">
          {/* <ToggleContainer>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Toggle active={yearly} onClick={toggle}>
                Yearly
              </Toggle>
              <Toggle className="right" active={!yearly} onClick={toggle}>
                Monthly
              </Toggle>
            </div>

            <h6 style={{ color: "#1c365e" }}>2 months free with yearly plan</h6>
          </ToggleContainer> */}

          <PricingHead yearly={yearly} />
          <LicenseTable />
          <LimitsTable />
          <MyPersonaFeatureTable />

          <p className="mt-2">
            Note: *Screen function not available for MAC users.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default PricingPage
