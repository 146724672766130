import React from "react"
import { Cross, Tick } from "."
import { Table, TableContainer } from "./styles"

export default function MyPersonaFeatureTable() {
  return (
    <TableContainer>
      <div>
        <Table className="border">
          <tr>
            <th style={{ textAlign: "left" }} colSpan={6}>
              My Persona Features
            </th>
          </tr>

          <tr>
            <th>Workspaces</th>
            <td>Max 2</td>
            <td>Max 5</td>
            <td>Unlimited</td>
          </tr>

          <tr>
            <th>Screen*</th>
            <td>Max 2</td>
            <td>Max 5</td>
            <td>Unlimited</td>
          </tr>

          <tr>
            <th>Import/Export Function</th>
            <td>
              <Cross />
            </td>
            <td>
              <Cross />
            </td>
            <td>
              <Tick />
            </td>
          </tr>

          <tr>
            <th>Proxy Setup for Each Workstation</th>
            <td>
              <Cross />
            </td>
            <td>
              <Tick />
            </td>
            <td>
              <Tick />
            </td>
          </tr>

          <tr>
            <th>JavaScript injection</th>
            <td>
              <Cross />
            </td>
            <td>
              <Tick />
            </td>
            <td>
              <Tick />
            </td>
          </tr>

          <tr>
            <th>Hibernation Function</th>
            <td>
              <Tick />
            </td>
            <td>
              <Tick />
            </td>
            <td>
              <Tick />
            </td>
          </tr>

          <tr>
            <th>Light/Dark Mode</th>
            <td>
              <Tick />
            </td>
            <td>
              <Tick />
            </td>
            <td>
              <Tick />
            </td>
          </tr>

          <tr>
            <th>Graphical Memory Usage Monitoring</th>
            <td>
              <Tick />
            </td>
            <td>
              <Tick />
            </td>
            <td>
              <Tick />
            </td>
          </tr>

          <tr>
            <th>Integrated Offline Password Manager</th>
            <td>
              <Tick />
            </td>
            <td>
              <Tick />
            </td>
            <td>
              <Tick />
            </td>
          </tr>

          <tr>
            <th>Toolbox - Notes</th>
            <td>
              <Tick />
            </td>
            <td>
              <Tick />
            </td>
            <td>
              <Tick />
            </td>
          </tr>

          <tr>
            <th>Toolbox - To Do Lists</th>
            <td>
              <Tick />
            </td>
            <td>
              <Tick />
            </td>
            <td>
              <Tick />
            </td>
          </tr>

          <tr>
            <th>Toolbox - Diary</th>
            <td>
              <Tick />
            </td>
            <td>
              <Tick />
            </td>
            <td>
              <Tick />
            </td>
          </tr>

          <tr>
            <th>Toolbox - History Audit Track</th>
            <td>
              <Tick />
            </td>
            <td>
              <Tick />
            </td>
            <td>
              <Tick />
            </td>
          </tr>

          <tr>
            <th>Toolbox - Downloads</th>
            <td>
              <Tick />
            </td>
            <td>
              <Tick />
            </td>
            <td>
              <Tick />
            </td>
          </tr>
        </Table>
      </div>
    </TableContainer>
  )
}
