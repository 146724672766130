import { Link } from "gatsby"
import styled from "styled-components"

export const TableContainer = styled.div`
  overflow-x: auto;
  margin-bottom: 20px;

  & > div {
    background-color: #fff;
    width: 100%;
    min-width: 900px;
    border: 2px solid #1c365e;
    border-radius: 15px;
    overflow: hidden;
  }
`

export const Table = styled.table`
  width: 100%;
  min-width: 900px;
  color: #1c365e;
  border-radius: 15px;
  overflow: hidden;
  height: 1px;
  text-align: center !important;

  & th {
    padding: 10px;
    font-weight: 700;
  }

  & td {
    padding: 10px;
    font-weight: 700;
    font-size: 0.8rem;
    width: 20% !important;
  }

  &.border,
  &.border th,
  &.border td {
    padding: 10px 15px;
    border: 1px solid #1c365e;
    border-collapse: collapse;
  }

  &.border tr th:first-child,
  &.border tr td:first-child {
    border-left: none;
  }

  &.border tr th:last-child,
  &.border tr td:last-child {
    border-right: none;
  }

  &.border tr:first-child th {
    border-top: none;
  }

  &.border tr:last-child th,
  &.border tr:last-child th {
    border-bottom: none;
  }

  & tr:first-child {
    width: 40% !important;
  }
`

export const ShadowBox = styled.div`
  width: 100%;
  padding: 20px 10px;
  background: #ffffff;
  border: 2px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  &.h100 {
    height: 100%;
  }

  &.p0 {
    padding: 0 !important;
  }

  &.p0 * {
    width: 100%;
  }

  & .head {
    padding: 0.5rem;
    border-radius: 0.5rem;
    background: rgba(249, 199, 18);
    width: 100% !important;
    font-size: 1.05rem;
    margin-bottom: 0.5rem;
  }

  & .price {
    font-size: 1.05rem;
  }
`

export const BorderBox = styled.div`
  border: 2px solid #1c365e;
  padding: 10px;

  &.rounded {
    border-radius: 7px;
  }
`

export const Button = styled(Link)`
  background-color: ${({ background }) => background};
  border-radius: 12px;
  padding: 12px;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: transform 0.5s ease-in-out;

  &:hover {
    opacity: 0.9;
    transform: scale(1.1);
  }
`
